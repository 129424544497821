import React, { useState, useEffect } from 'react';
import ChatMessage from './ChatMessage';
import LoadingIndicator from './LoadingIndicator';
import Button from './Button';
import PreviewTable from './PreviewTable';
import { sendMessage, submitData, getModules, getReferences, getTables, getColumns, getReferencesValues ,getWindows,getTabs,getFieldsAndSequenceNo} from '../services/api';
import '../styles/ChatBot.css';

const ChatBot = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [graphqlLogs, setGraphqlLogs] = useState([]);
  const [previewData, setPreviewData] = useState(null);
  const [modules, setModules] = useState([]);
  const [references, setReferences] = useState([]);
  const [tables, setTables] = useState([]);
  const [columns, setColumns] = useState([]);
  const [referencesValues, setReferencesValues] = useState([]);
  const [windows, setWindows] = useState([]);
  const [tabs, setTabs] = useState([]);
  const [sequences, setSequences] = useState([]);


  useEffect(() => {
    const fetchData = async () => {
      try {
        const [modulesData, referencesData, tablesData, referencesValuesData,windowsData] = await Promise.all([
          getModules(),
          getReferences(),
          getTables(),
          getReferencesValues(),
          // getWindows()
        ]);
        // console.log(windowsData)
        setModules(modulesData);
        setReferences(referencesData);
        setTables(tablesData);
        setReferencesValues(referencesValuesData);
        // setWindows(windowsData)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    fetchData();
  }, []);

  const handleSend = async () => {
    if (input.trim() === '') return;

    setMessages(prevMessages => [...prevMessages, { text: input, isUser: true }]);
    setInput('');
    setIsLoading(true);

    try {
      const response = await sendMessage(input);
      console.log(response);
      setMessages(prevMessages => [...prevMessages, {
        text: response?.message,
        isUser: false,
        isSuccess: response?.details?.status === 'success',
        isError: response?.details?.status === 'error'
      }]);
      setGraphqlLogs(response.graphql_logs);

      if (response.action !== 'error') {
        const updatedVariables = { ...response.details.variables };

        // Replace IDs with names
        if (updatedVariables.nt_module_id) {
          const module = modules.find(m => m.nt_module_id === updatedVariables.nt_module_id);
          updatedVariables.module_name = module ? module.name : updatedVariables.nt_module_id;
        }
        if (updatedVariables.nt_base_reference_id) {
          const reference = references.find(r => r.id === updatedVariables.nt_base_reference_id);
          updatedVariables.data_type = reference ? reference.name : updatedVariables.nt_base_reference_id;
        }

        setPreviewData({
          action: response.action,
          variables: updatedVariables
        });

        // Fetch columns if foreign_key_table is present
        if (updatedVariables.foreign_key_table) {
          console.log(tables)
          console.log(updatedVariables)
          const tableId = tables.filter(res=>res.tablename === updatedVariables.foreign_key_table)
          console.log(tableId)
          const columnsData = await getColumns(tableId[0]?.id);
          setColumns(columnsData);
        } else if (updatedVariables.references_values) {
          const referencesValuesData = await getReferencesValues(updatedVariables.references_values);
          setReferencesValues(referencesValuesData);
        }
        // Fetch tabs if ad_window_id is present
        if (updatedVariables.ad_window_id) {
          const tabsData = await getTabs(updatedVariables.ad_window_id);
          setTabs(tabsData);
        }

        // Fetch sequences if ad_tab_id is present
        if (updatedVariables.ad_tab_id) {
          const sequencesData = await getFieldsAndSequenceNo(updatedVariables.ad_tab_id);
          setSequences(sequencesData);
        }
      } else {
        setPreviewData(null);
      }
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [...prevMessages, {
        text: `Error: ${error.message}`,
        isUser: false,
        isError: true
      }]);
      setPreviewData(null);
    } finally {
      setIsLoading(false);
    }
  };

  const handleSubmit = async () => {
    console.log(previewData)
    setIsLoading(true);
    try {
      let formattedData;
      if(previewData.action === "edit_sequence"){
        formattedData = {
          action: previewData.action,
          variables: {
            sequences: previewData.variables.fields_and_sequences.fields.map(field => ({
              ad_field_id: field.ad_field_id,
              seqno: field.seqno
            }))
          }
        };
      }else{
        formattedData = previewData
      }
      
      const response = await submitData(formattedData);
      setMessages(prevMessages => [...prevMessages, { 
        text: response.message, 
        isUser: false,
        isSuccess: response.details.status === 'success',
        isError: response.details.status === 'error'
      }]);
      setGraphqlLogs(response.graphql_logs);
      setPreviewData(null);
    } catch (error) {
      console.error('Error:', error);
      setMessages(prevMessages => [...prevMessages, { 
        text: `Error: ${error.message}`, 
        isUser: false,
        isError: true
      }]);
    } finally {
      setIsLoading(false);
    }
  };


  const handlePreviewUpdate = async(updatedVariables) => {
    setPreviewData(prevData => ({
      ...prevData,
      variables: updatedVariables
    }));

    if (updatedVariables.foreign_key_table !== previewData.variables.foreign_key_table) {
      console.log(updatedVariables)
      const columnsData = await getColumns(updatedVariables.foreign_key_table);
      setColumns(columnsData);
    }

    if (updatedVariables.ad_window_id !== previewData.variables.ad_window_id) {
      const tabsData = await getTabs(updatedVariables.ad_window_id);
      setTabs(tabsData);
    }

    if (updatedVariables.ad_tab_id !== previewData.variables.ad_tab_id) {
      const sequencesData = await getFieldsAndSequenceNo(updatedVariables.ad_tab_id);
      setSequences(sequencesData);
    }
  };

  const handleCancel = () => {
    setPreviewData(null);
    setMessages(prevMessages => [...prevMessages, { 
      text: "Operation cancelled.", 
      isUser: false
    }]);
  };

  return (
    <div className="chatbot-container">
      <div className="chatbot">
        <div className="chatbot-header">
          <img src={process.env.PUBLIC_URL + '/logo.png'} className="company-logo" alt="Company Logo" />
          <h2>CNE.noton</h2>
        </div>
        <p className="chatbot-subheader">I am CNE.notonbot!</p>
        <div className="chatbot-messages">
          {messages.map((message, index) => (
            <ChatMessage key={index} message={message} logs={graphqlLogs}/>
          ))}
          {isLoading && <LoadingIndicator />}
          {previewData && (
            <PreviewTable 
              data={previewData.variables} 
              onUpdate={handlePreviewUpdate}
              onSubmit={handleSubmit}
              onCancel={handleCancel}
              modules={modules}
              references={references}
              tables={tables}
              columns={columns}
              referencesValues={referencesValues}
              windows={windows}
              tabs={tabs}
              sequences={sequences}
              setWindows={setWindows}
            />
          )}
        </div>
        <div className="chatbot-input">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyDown={(e) => e.key === 'Enter' && handleSend()}
            placeholder="Type your message..."
            style={{background:"#f3f4f9"}}
          />
          <Button onClick={handleSend} disabled={isLoading || input.trim() === ''}>Send</Button>
        </div>
      </div>
    </div>
  );
};

export default ChatBot;