import React from 'react';
import '../styles/GraphQLLogs.css';

const GraphQLLogs = ({ logs }) => {
  if (logs?.length === 0) return null;

  return (
    <div className="graphql-logs">
      <h3>GraphQL Logs</h3>
      {logs.map((log, index) => (
        <div key={index} className="log-entry">
          {/* <h4>Request:</h4>
          <pre>{JSON.stringify(log.query, null, 2)}</pre> */}
          <h4>Variables:</h4>
          <pre>{JSON.stringify(log.variables, null, 2)}</pre>
        </div>
      ))}
    </div>
  );
};

export default GraphQLLogs;