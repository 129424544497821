import React, { useState, useEffect } from 'react';
import Button from './Button';
import '../styles/PreviewTable.css';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { getColumns, getReferencesValues, getTabs, getFieldsAndSequenceNo, getWindows, getTables } from '../services/api';

const PreviewTable = ({
  data,
  onUpdate,
  onSubmit,
  onCancel,
  modules,
  references,
  tables,
  setTables,
  columns,
  referencesValues,
}) => {
  const [editedData, setEditedData] = useState(data);
  const [windows, setWindows] = useState([]);
  const [localColumns, setLocalColumns] = useState(columns);
  const [localReferencesValues, setLocalReferencesValues] = useState(referencesValues);
  const [tabs, setTabs] = useState([]);
  const [sequences, setSequences] = useState([]);
  // const [tables,setTables] = useState([]);

  useEffect(() => {
    const fetchWindows = async () => {
      if (editedData.nt_module_id) {
        try {
          const windowdata = await getWindows(editedData.nt_module_id);
          if (windowdata && windowdata.windows) {
            setWindows(windowdata.windows);
          } else {
            console.error('Unexpected data structure for windows:', windowdata);
          }
        } catch (error) {
          console.error('Error fetching windows:', error);
        }
      }
    };

    fetchWindows();
  }, [editedData.module_id]);

  useEffect(() => {
    const fetchData = async () => {
      console.log(editedData, tables)
      if (editedData.data_type === 'TableDir' && editedData.foreign_key_table) {
        const tableId = tables.filter(tabna => editedData.foreign_key_table === tabna.tablename)
        console.log(tableId)
        await fetchColumns(tableId[0]?.id);
      }
      if (editedData.data_type === 'List' && editedData.references_values) {
        await fetchReferencesValues(editedData.references_values);
      }
      if (editedData.window_id) {
        await fetchTabs(editedData.window_id);
      }
      // if (editedData.ad_table_id) {
      //   await fetchTables(editedData.window_id);
      // }
    };

    fetchData();
  }, [editedData.data_type, editedData.foreign_key_table, editedData.references_values, editedData.ad_window_id]);

  useEffect(() => {
    if (editedData.ad_tab_id) {
      fetchFieldsAndSequenceNo(editedData.ad_tab_id);
    }
  }, [editedData.ad_tab_id]);

  useEffect(() => {
    if (editedData.fields_and_sequences && editedData.fields_and_sequences.fields) {
      setSequences(editedData.fields_and_sequences.fields);
    }
  }, [editedData]);

  const fetchColumns = async (tableId) => {
    console.log(tableId)
    try {
      const columnsData = await getColumns(tableId);
      setLocalColumns(columnsData);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  };

  const fetchTables = async () => {
    try {
      const tablesData = await getTables();
      setTables(tablesData);
    } catch (error) {
      console.error('Error fetching columns:', error);
    }
  };

  const fetchFieldsAndSequenceNo = async (tabId) => {
    try {
      const sequenceData = await getFieldsAndSequenceNo(tabId);
      setSequences(sequenceData);
    } catch (error) {
      console.error('Error fetching field sequences:', error);
    }
  };

  const fetchReferencesValues = async (referencesValuesId) => {
    try {
      const referencesValuesData = await getReferencesValues(referencesValuesId);
      setLocalReferencesValues(referencesValuesData);
    } catch (error) {
      console.error('Error fetching references values:', error);
    }
  };

  const fetchTabs = async (windowId) => {
    try {
      const tabsData = await getTabs(windowId);
      console.log(tabsData)
      setTabs(tabsData.tabs);
    } catch (error) {
      console.error('Error fetching tabs:', error);
    }
  };

  const handleInputChange = async (key, value) => {
    let updatedData = { ...editedData, [key]: value };

    if (key === 'module_name') {
      const selectedModule = modules.find(m => m.name === value);
      updatedData.nt_module_id = selectedModule ? selectedModule.nt_module_id : '';
    } else if (key === 'data_type') {
      const selectedReference = references.find(r => r.name === value);
      updatedData.nt_base_reference_id = selectedReference ? selectedReference.id : '';
      if (value !== 'TableDir') {
        updatedData.foreign_key_table = '';
        updatedData.foreign_key_identifiers = '';
      }
      if (value !== 'List') {
        updatedData.references_values = '';
      } else {
        await fetchReferencesValues(updatedData.references_values);
      }
    } else if (key === 'foreign_key_table') {
      await fetchColumns(value);
    } else if (key === 'references_values') {
      await fetchReferencesValues(value);
    } else if (key === 'ad_tab_id') {
      fetchFieldsAndSequenceNo(value);
    }

    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  const handleSequenceChange = (fieldId, newSequence) => {
    const updatedSequences = sequences.map(seq =>
      seq.ad_field_id === fieldId ? { ...seq, seqno: newSequence } : seq
    );
    setSequences(updatedSequences);
    const updatedData = {
      ...editedData,
      fields_and_sequences: { ...editedData.fields_and_sequences, fields: updatedSequences }
    };
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  const renderInput = (key, value) => {
    console.log(key, "----->key")
    if (key === 'module_id' || key === 'module_name') {
      return (
        <select value={value} onChange={(e) => handleInputChange(key, e.target.value)}>
          {modules.map(module => (
            <option key={module.nt_module_id} value={module.name}>{module.name}</option>
          ))}
        </select>
      );
    } else if (key === 'window_id') {
      console.log(windows)
      return (
        <select value={value} onChange={(e) => handleInputChange(key, e.target.value)}>
          {windows.length > 0 ? (
            windows.map(window => (
              <option key={window.ad_window_id} value={window.ad_window_id}>
                {window.name}
              </option>
            ))
          ) : (
            <option value="" disabled>No windows available</option>
          )}
        </select>
      );
    } else if (key === 'data_type') {
      return (
        <select value={value} onChange={(e) => handleInputChange(key, e.target.value)}>
          {references.map(reference => (
            <option key={reference.id} value={reference.name}>{reference.name}</option>
          ))}
        </select>
      );
    } else if (key === 'foreign_key_table' && editedData.data_type === 'TableDir') {

      let tabId = tables.filter(res => res.tablename === value)
      return (
        <select value={tabId[0]?.id} onChange={(e) => handleInputChange(key, e.target.value)}>
          <option value="">Select a table</option>
          {tables.map(table => (
            <option key={table.id} value={table.id}>{table.tablename}</option>
          ))}
        </select>
      );
    } else if (key === 'foreign_key_identifiers' && editedData.data_type === 'TableDir') {
      let columnName = localColumns.filter(res => res.columnname === value)
      return (
        <select value={columnName[0]?.id} onChange={(e) => handleInputChange(key, e.target.value)}>
          <option value="">Select a column</option>
          {localColumns.map(column => (
            <option key={column.id} value={column.id}>{column.columnname}</option>
          ))}
        </select>
      );
    } else if (key === 'references_values' && editedData.data_type === 'List') {
      return (
        <select value={value} onChange={(e) => handleInputChange(key, e.target.value)}>
          <option value="">Select a reference</option>
          {localReferencesValues.map(reference => (
            <option key={reference.id} value={reference.id}>{reference.name}</option>
          ))}
        </select>
      );
    } else if (key === 'tab_id') {
      return (
        <select value={value} onChange={(e) => handleInputChange(key, e.target.value)}>
          <option value="">Select a tab</option>
          {tabs?.map(tab => (
            <option key={tab.ad_tab_id} value={tab.ad_tab_id}>{tab.name}</option>
          ))}
        </select>
      );
    } else if (key === 'ad_table_id') {
      return (
        <select value={value} onChange={(e) => handleInputChange(key, e.target.value)}>
          <option value="">Select table</option>
          {tables?.map(tab => (
            <option key={tab.id} value={tab.id}>{tab.tablename}</option>
          ))}
        </select>
      );
    } else {
      return (
        <input
          type="text"
          value={value}
          onChange={(e) => handleInputChange(key, e.target.value)}
        />
      );
    }
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const reorderedSequences = Array.from(sequences);
    const [removed] = reorderedSequences.splice(result.source.index, 1);
    reorderedSequences.splice(result.destination.index, 0, removed);

    // Ensure the sequence numbers remain unchanged
    const updatedSequences = reorderedSequences.map((seq, index) => ({
      ...seq,
      seqno: sequences[index].seqno,
    }));

    setSequences(updatedSequences);
    const updatedData = {
      ...editedData,
      fields_and_sequences: { ...editedData.fields_and_sequences, fields: updatedSequences },
    };
    setEditedData(updatedData);
    onUpdate(updatedData);
  };

  const getKeyName = (key) => {
    switch (key) {
      case "name":
        return "Name";

      case "window_id":
        return "Window";

      case "tab_id":
        return "Tab";

      case "module_name":
        return "Module";

      case "module_name":
        return "Module";

      case "ad_table_id":
        return "Table";

      case "foreign_key_identifiers":
        return "FK Identifier";

      case "foreign_key_table":
        return "FK Table ";

        case "columnname":
        return "Column";

      case "data_type":
        return "Column Type";
      // Add more cases as needed
      default:
        return key; // Return the key itself if no cases match
    }
  };

  return (
    <div className="preview-table">
      <h3>Preview Data</h3>
      <table>
        <tbody>
          {Object.entries(editedData).map(([key, value]) => (
            (key !== 'nt_module_id' && key !== 'nt_base_reference_id' && key !== 'nt_reference_id') &&
            (editedData.data_type === 'List' || key !== 'references_values') &&
            (editedData.data_type === 'TableDir' || (key !== 'foreign_key_table' && key !== 'foreign_key_identifiers')) && key !== 'fields_and_sequences' &&
            (
              <tr key={key}>
                <td>{getKeyName(key)}</td>
                <td>{renderInput(key, value)}</td>
              </tr>
            )
          ))}
        </tbody>
      </table>
      {sequences.length > 0 && (
        <div className="sequence-editor">
          <h4>Edit Sequences</h4>
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="sequences">
              {(provided) => (
                <table {...provided.droppableProps} ref={provided.innerRef}>
                  <thead>
                    <tr>
                      <th>Field Name</th>
                      <th>Sequence Number</th>
                    </tr>
                  </thead>
                  <tbody>
                    {sequences.map((seq, index) => (
                      <Draggable key={seq.ad_field_id} draggableId={seq.ad_field_id} index={index}>
                        {(provided) => (
                          <tr
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                          >
                            <td>{seq.name}</td>
                            <td>
                              <input
                                type="number"
                                value={seq.seqno}
                                onChange={(e) => handleSequenceChange(seq.ad_field_id, e.target.value)}
                              />
                            </td>
                          </tr>
                        )}
                      </Draggable>
                    ))}
                    {provided.placeholder}
                  </tbody>
                </table>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      )}
      <div className="preview-actions">
        <Button onClick={() => onSubmit(editedData)}>Submit</Button>
        <Button onClick={onCancel}>Cancel</Button>
      </div>
    </div>
  );
};

export default PreviewTable;
