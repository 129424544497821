import React, { useState } from 'react';
import '../styles/ChatMessage.css';
import GraphQLLogs from './GraphQLLogs';

const ChatMessage = ({ message, logs }) => {
  const [showLogs, setShowLogs] = useState(false);

  return (
    <div className={`chat-message ${message.isUser ? 'user' : 'bot'} ${message.isError ? 'error' : ''} ${message.isSuccess ? 'success' : ''}`}>
      {message.text}
      {!message.isUser && logs && (
        <div>
          <button onClick={() => setShowLogs(!showLogs)}>
            {showLogs ? 'Hide Logs' : 'Show Logs'}
          </button>
          {showLogs && <GraphQLLogs logs={logs} />}
        </div>
      )}
    </div>
  );
};

export default ChatMessage;