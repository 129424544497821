// import axios from 'axios';

// const API_URL = 'http://localhost:8000'; // Replace with your API URL
// const TIMEOUT_DURATION = 20000; // Increase the timeout duration
// const MAX_RETRIES = 3; // Define the maximum number of retries

// export const sendMessage = async (prompt) => {
//   let attempts = 0;

//   while (attempts < MAX_RETRIES) {
//     try {
//       const response = await axios.post(`${API_URL}/chat/`, { prompt }, { timeout: TIMEOUT_DURATION });
//       if (response.data && response.data.message) {
//         return { status: 'success', message: response.data.message, data: response.data };
//       } else {
//         throw new Error('Invalid response from server');
//       }
//     } catch (error) {
//       console.error('Error in sendMessage:', error);
//       if (error.code === 'ECONNABORTED') {
//         console.log('Connection timeout. Retrying...');
//       } else if (error.response) {
//         throw new Error(error.response.data.detail || 'Server error occurred');
//       } else if (error.request) {
//         throw new Error('No response from server. Please check your connection.');
//       } else {
//         throw new Error('An unexpected error occurred. Please try again.');
//       }
//     }
//     attempts += 1;
//   }
//   throw new Error('Maximum retry attempts reached. Please try again later.');
// };

// export const submitData = async (data) => {
//   const response = await axios.post(`${API_URL}/submit/`, data);
//   return response.data;
// };

import axios from 'axios';

const API_URL = 'https://services.cne.test.noton.dev/';
const token = localStorage.getItem("token")?.replace(/"/g, ''); 
// const API_URL = 'https://services.cne.test.noton.dev/';
const yourAuthToken = `Bearer ${token}`;


const client_id = localStorage.getItem("clientId")?.replace(/"/g, ''); 
const headers = {
  'Content-Type': 'application/json',
  'Authorization': yourAuthToken,
  'nt-user-id': client_id
};

export const sendMessage = async (message) => {
  try {
    // console.log(yourAuthToken,"-------------->yourAuthToken")
    const response = await axios.post(`${API_URL}/chat/`, 
      { prompt: message }, 
      {
        headers: {
          'Content-Type': 'application/json',
          'Authorization': yourAuthToken, // Ensure 'Bearer' is used if required
          'nt-user-id': client_id
        }
      }
    );

    return response.data;
  } catch (error) {
    // Handle errors thrown by axios
    if (error.response) {
      throw new Error(`Error: ${error.response.status} - ${error.response.data.message}`);
    } else {
      throw new Error(`Error: ${error.message}`);
    }
  }
};

export const submitData = async (data) => {
  try {
    const response = await axios.post(`${API_URL}/submit/`, 
      data, 
      {
        headers: {
          'Authorization': yourAuthToken, // Ensure 'Bearer' is used if required
          'nt-user-id': client_id
        }
      }
    );

    return response.data;
  } catch (error) {
    // Handle errors thrown by axios
    if (error.response) {
      throw new Error(`Error: ${error.response.status} - ${error.response.statusText}`);
    } else {
      throw new Error(`Error: ${error.message}`);
    }
  }
};

export const getModules = async () => {
  try {
    const response = await fetch(`${API_URL}/modules`);
    if (!response.ok) {
      throw new Error('Failed to fetch modules');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching modules:', error);
    throw error;
  }
};

export const getReferences = async () => {
  try {
    const response = await fetch(`${API_URL}/specific_base_references`);
    if (!response.ok) {
      throw new Error('Failed to fetch references');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching references:', error);
    throw error;
  }
};
export const getTables = async () => {
  try {
    const response = await fetch(`${API_URL}/tables`);
    if (!response.ok) {
      throw new Error('Failed to fetch tables');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching tables:', error);
    throw error;
  }
};

export const getColumns = async (tableId) => {
  try {
    const response = await fetch(`${API_URL}columns/${tableId}`);
    if (!response.ok) {
      throw new Error('Failed to fetch columns');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching columns:', error);
    throw error;
  }
};

export const getReferencesValues = async () => {
  try {
    const response = await fetch(`${API_URL}/references`);
    if (!response.ok) {
      throw new Error('Failed to fetch references values');
    }
    return await response.json();
  } catch (error) {
    console.error('Error fetching references values:', error);
    throw error;
  }
};

export const getWindows = async (moduleId) => {
  try {
    const response = await axios.get(`${API_URL}windows/${moduleId}`, { headers });
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching windows');
  }
};

export const getTabs = async (windowId) => {
  try {
    const response = await axios.get(`${API_URL}/tabs/${windowId}`, { headers });
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching tabs');
  }
};

export const getFieldsAndSequenceNo = async (tabId) => {
  try {
    const response = await axios.get(`${API_URL}/sequence_no/${tabId}`, { headers });
    return response.data;
  } catch (error) {
    handleError(error, 'Error fetching field sequences');
  }
};

const handleError = (error, defaultMessage = 'An error occurred') => {
  if (error.response) {
    throw new Error(`${defaultMessage}: ${error.response.status} - ${error.response.data.message}`);
  } else {
    throw new Error(`${defaultMessage}: ${error.message}`);
  }
};