import React from 'react';
import ChatBot from './components/ChatBot';
import './App.css';

function App() {
  localStorage.setItem("clientId","5feb24f1c7d8b67944bd7565")
  localStorage.setItem("token","eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE3MjI1NzY1ODAsInVzZXJfbmFtZSI6Im5vdG9uIiwiYXV0aG9yaXRpZXMiOlsiUk9MRV9TWVNURU1BRE1JTiJdLCJqdGkiOiJlMTFmNTE3OC1kMTc4LTQ0ZTItODY1Ny1kZjk0NTg0ZjA2ZmEiLCJjbGllbnRfaWQiOiJ0YWxrMmFtYXJlc3dhcmFuIiwic2NvcGUiOlsicmVhZCIsIndyaXRlIl19.rBQF4yisWLCwWiCx-IaOJjIl59_PIJXB6DnyidEGZCk")
  return (
    <div className="App">
      <ChatBot />
    </div>
  );
}

export default App;